import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-intl'

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Layout from '@components/Layout';
import Transitions from '@components/Transitions';
import Section from '@components/Section';
import HeroWrapper from '@components/Hero/Hero.Wrapper';
import Wrapper from '@components/Wrapper';
import Grid from '@components/Grid';
import Headings from '@components/Headings';
import HeadingsContainer from '@components/Headings/Headings.Container';
import Paragraph from '@components/Paragraph';
import CardDefault from '@components/Card/Card.Default';
import CardImageContainer from '@components/Card/Card.Image';
import CardBorder from '@components/Card/Card.Border';
import CardContent from '@components/Card/Card.Content';

import SEO from '../components/seo';
import Image from '../components/Image';

import PageNotFound from '@icons/undraw/PageNotFound.Icon';


const NotFoundPage = () => {
    const intl = useIntl();
    const invertHeader = false;

    return (
    <Layout id="not-found" invertHeader={invertHeader}>
        <SEO
            /*lang={intl.locale}*/
            lang={intl.formatMessage({ id: 'current' })}
            title={intl.formatMessage({ id: '404' })}
            noIndex={true}
        />

        <Transitions.CSS.FadeIn>
            <Section className="small-hero">
                <Wrapper>
                    <HeroWrapper>
                        <Transitions.CSS.FadeInUp delay="0.1">
                            <Heading center>{intl.formatMessage({ id: 'not-found' })}</Heading>
                        </Transitions.CSS.FadeInUp>
                        <Transitions.CSS.FadeInUp delay="0.2">
                            <Paragraph center subtitle>{intl.formatMessage({ id: 'not-found-description' })}</Paragraph>
                        </Transitions.CSS.FadeInUp>
                        <Transitions.CSS.FadeInUp delay="0.3">
                            <Icon404 />
                        </Transitions.CSS.FadeInUp>
                    </HeroWrapper>
                </Wrapper>
            </Section>
        </Transitions.CSS.FadeIn>

        <Section small>
            <Wrapper>
                <HeadingsContainer center>
                    <Headings.h2>Tekintsd meg szolgátatásainkat!</Headings.h2>
                    {/*<Paragraph center lead>{intl.formatMessage({ id: 'services-subtitle' })}</Paragraph>*/}
                </HeadingsContainer>
                <StyledGrid columns="4">
                    <Link to="/web-development-services/">
                        <CardDefault hover animateImage animateBorder>
                            <CardImageContainer>
                                <Image imgName="macbook-keyboard.jpg" />
                                <CardBorder gradient className="card-border" />
                            </CardImageContainer>
                            <StyledCardContent>
                                <CardHeader>{intl.formatMessage({ id: 'services-web' })}</CardHeader>
                                {/*<CardParagraph>{intl.formatMessage({ id: 'services-web-excerpt' })}</CardParagraph>*/}
                            </StyledCardContent>
                        </CardDefault>
                    </Link>

                    <Link to="/seo-services/">
                        <CardDefault hover animateImage animateBorder>
                            <CardImageContainer>
                                <Image imgName="mac-on-desk.jpg" />
                                <CardBorder gradient className="card-border" />
                            </CardImageContainer>
                            <StyledCardContent>
                                <CardHeader>{intl.formatMessage({ id: 'services-seo' })}</CardHeader>
                                {/*<CardParagraph>{intl.formatMessage({ id: 'services-seo-excerpt' })}</CardParagraph>*/}
                            </StyledCardContent>
                        </CardDefault>
                    </Link>

                    <Link to="/online-marketing/">
                        <CardDefault hover animateImage animateBorder>
                            <CardImageContainer>
                                <Image imgName="girl-writing-in-notebook.jpg" />
                                <CardBorder gradient className="card-border" />
                            </CardImageContainer>
                            <StyledCardContent>
                                <CardHeader>{intl.formatMessage({ id: 'services-marketing' })}</CardHeader>
                                {/*<CardParagraph>{intl.formatMessage({ id: 'services-marketing-excerpt' })}</CardParagraph>*/}
                            </StyledCardContent>
                        </CardDefault>
                    </Link>

                    <Link to="/app-development/">
                        <CardDefault hover animateImage animateBorder>
                            <CardImageContainer>
                                <Image imgName="macbook-programming.jpg" />
                                <CardBorder gradient className="card-border" />
                            </CardImageContainer>
                            <StyledCardContent>
                                <CardHeader>{intl.formatMessage({ id: 'services-app' })}</CardHeader>
                                {/*<CardParagraph>{intl.formatMessage({ id: 'services-app-excerpt' })}</CardParagraph>*/}
                            </StyledCardContent>
                        </CardDefault>
                    </Link>
                </StyledGrid>
            </Wrapper>
        </Section>
    </Layout>
    );
};

const Heading = styled(Headings.h1)`
    font-weight: 800;
`;

const CardHeader = styled(Headings.h4)`
    margin-bottom: 0px;
    // text-align: center;
    font-weight: bold;
`;

// const CardParagraph = styled(Paragraph)`
//     margin-bottom: 0px;
//     font-size: 16px;
// `;

const Icon404 = styled(PageNotFound)`
    height: 100%;
    width: 80%;
    margin: 0px auto;

    ${mediaqueries.from_laptop`
        width: 50%;
    `}
`;

const StyledGrid = styled(Grid)`
    margin-top: 50px;
`;

const StyledCardContent = styled(CardContent)`
    padding: 7% 5%;
`;

export default NotFoundPage;
