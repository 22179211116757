import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

// Come Back Here and Fix CSS
const CardBorder = styled.div<{ gradient?: boolean, static?: boolean }>`
    // This is here because globals are not being loaded into the document 
    --brand-color-blue: rgb(44, 107, 246);
    --brand-color-green: rgb(73, 240, 230);
    --linear-gradient-background: linear-gradient( to right, var(--brand-color-green), var(--brand-color-blue) 100%);

    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 5px;
    z-index: 2;
    background: var(--brand-color-blue);
    transition: transform 0.4s ease 0s, opacity 0.4s ease 0s;
    
    ${p => p.static
        ? mediaqueries.from_tablet_ipad`
            opacity: 1;
        ` : mediaqueries.from_tablet_ipad`
            transform: translate3d(-100%, 0px, 0px);
            opacity: 0;
        `};
    

    ${p => p.gradient && `
        background: var(--linear-gradient-background);
        background: linear-gradient(to left, #12ABFF, #0094FF 100%);
    `};
`;

export default CardBorder;
